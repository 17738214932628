import ProductOverview from '@/assets/images/sidebar/price-productOverview.svg'
import TransportService from '@/assets/images/sidebar/price-transportService.svg'
import LastRouteService from '@/assets/images/sidebar/price-lastRouteService.svg'
import MyUsual from '@/assets/images/sidebar/price-myUsual.svg'
import MyCollection from '@/assets/images/sidebar/price-myCollection.svg'
export default [
    {
        name: 'price',
        path: '/price',
        component: () => import("@/views/price"),
        redirect: "",
        children: [
            {
                name: 'priceProductOverview',
                path: '/price/productOverview',
                component: () => import("@/views/price/productOverview"),
                redirect: "/price/productOverview/index",
                meta: {
                    title: '产品概览',
                    icon: ProductOverview,
                    isShowing: true,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'priceProductOverviewIndex',
                        path: '/price/productOverview/index',
                        component: () => import("@/views/price/productOverview/productOverview-index"),
                        meta: {
                            title: "产品概览",
                            isShowing: false,
                            activeMenu: 'priceProductOverview'
                        }
                    },
                ]
            },
            {
                name: 'priceTransportService',
                path: '/price/transportService',
                component: () => import("@/views/price/transportService"),
                redirect: "/price/transportService/index",
                meta: {
                    title: '运输服务',
                    icon: TransportService,
                    isShowing: true,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'priceTransportServiceIndex',
                        path: '/price/transportService/index',
                        component: () => import("@/views/price/transportService/transportService-index"),
                        meta: {
                            title: "运输服务",
                            isShowing: false,
                            activeMenu: 'priceTransportService'
                        }
                    },
                    {
                        name: 'priceTransportServiceQuickFirstOrder',
                        path: '/price/transportService/quickFirstOrder',
                        component: () => import("@/views/price/transportService/transportService-quickOrder"),
                        meta: {
                            title: "新建运单",
                            isShowing: false,
                            activeMenu: 'priceTransportService'
                        }
                    }
                ]
            },
            {
                name: 'priceLastRouteService',
                path: '/price/lastRouteService',
                component: () => import("@/views/price/lastRouteService"),
                redirect: "/price/lastRouteService/index",
                meta: {
                    title: '后端服务',
                    icon: LastRouteService,
                    isShowing: true,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'priceLastRouteServiceIndex',
                        path: '/price/lastRouteService/index',
                        component: () => import("@/views/price/lastRouteService/lastRouteService-index"),
                        meta: {
                            title: "后端服务",
                            isShowing: false,
                            activeMenu: 'priceLastRouteService'
                        }
                    },
                    {
                        name: 'priceLastRouteServiceQuickLastOrder',
                        path: '/price/lastRouteService/quickLastOrder',
                        component: () => import("@/views/price/lastRouteService/lastRouteService-quickOrder"),
                        meta: {
                            title: "新建运单",
                            isShowing: false,
                            activeMenu: 'priceLastRouteService'
                        }
                    }
                ]
            },
            {
                name: 'priceMyUsual',
                path: '/price/myUsual',
                component: () => import("@/views/price/myUsual"),
                redirect: "/price/myUsual/index",
                meta: {
                    title: '我的常用',
                    icon: MyUsual,
                    isShowing: true,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'priceMyUsualIndex',
                        path: '/price/myUsual/index',
                        component: () => import("@/views/price/myUsual/myUsual-index"),
                        meta: {
                            title: "我的常用",
                            isShowing: false,
                            activeMenu: 'priceMyUsual'
                        }
                    },
                ]
            },
            {
                name: 'priceMyCollection',
                path: '/price/myCollection',
                component: () => import("@/views/price/myCollection"),
                redirect: "/price/myCollection/index",
                meta: {
                    title: '我的收藏',
                    icon: MyCollection,
                    isShowing: true,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'priceMyCollectionIndex',
                        path: '/price/myCollection/index',
                        component: () => import("@/views/price/myCollection/myCollection-index"),
                        meta: {
                            title: "我的收藏",
                            isShowing: false,
                            activeMenu: 'priceMyCollection'
                        }
                    },
                ]
            },
        ]
    }
]