import "core-js/modules/es.array.push.js";
import { onMounted, getCurrentInstance, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { logoutAPI, getInfoAPI } from '@/api/user/user';
import { useStore } from 'vuex';
import Avatar from '@/assets/images/header/avatar.svg';
export default {
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const userInfo = computed(() => store.state.user.userInfo);
    const selectedPart = computed(() => store.state.user.selectedPart);
    const userRouter = computed(() => store.state.user.userRouter);
    const selectPartList = computed(() => store.state.user.selectPartList);
    // 监听路由变化更改模块
    watch(() => route, val => {
      const path = val.path.split('/');
      store.commit('user/changeSelectedPart', path[1]);
      localStorage.setItem("selectedPart", path[1]);
    }, {
      deep: true,
      immediate: true
    });
    onMounted(() => {
      getInfo();
    });
    // 跳转首页
    const toIndex = () => {
      router.push('/');
    };
    // 获取用户信息
    const getInfo = async () => {
      try {
        const res = await getInfoAPI();
        store.commit('user/changeUserInfo', res);
      } catch (error) {
        error.error ? proxy.$TcMsg({
          type: "error",
          text: error.error
        }) : proxy.$TcMsg({
          type: "error",
          text: "获取失败"
        });
      }
    };
    // 退出登录
    const logout = async () => {
      proxy.$TcMsg({
        type: "loading",
        text: "退出中"
      });
      try {
        await logoutAPI();
        proxy.$TcMsg({
          type: "success",
          text: "退出成功"
        });
        localStorage.removeItem("authKey");
        localStorage.removeItem("tagList");
        localStorage.removeItem("selectedPart");
        router.push('/login');
      } catch (error) {
        error.error ? proxy.$TcMsg({
          type: "error",
          text: error.error
        }) : proxy.$TcMsg({
          type: "error",
          text: "退出失败"
        });
      }
    };
    // 更改模块
    const changeSelectPart = item => {
      // 跟踪模块特殊处理
      if (item.name === 'trackDown') {
        router.push('/trackDown');
        return;
      }
      store.commit('user/changeSelectedPart', item.name);
      localStorage.setItem("selectedPart", item.name);
      setTimeout(() => {
        router.push(userRouter.value[0].children[0].path);
      }, 0);
    };

    // 静默打印
    const toSilentPrinting = () => {
      router.push('/print');
    };
    // 操作指引
    const toInstruction = () => {
      router.push('/instruction');
    };
    return {
      userInfo,
      selectedPart,
      selectPartList,
      changeSelectPart,
      toIndex,
      logout,
      Avatar,
      toInstruction,
      toSilentPrinting,
      route
    };
  }
};