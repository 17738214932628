import WaybillSpecialLine from '@/assets/images/sidebar/waybill-specialLine.svg'
import waybillSmallPacket from '@/assets/images/sidebar/waybill-smallPacket.svg'
import waybillFullContainerLoad from '@/assets/images/sidebar/waybill-fullContainerLoad.svg'
import waybillStorage from '@/assets/images/sidebar/waybill-storage.svg'
import waybillAgentDelivery from '@/assets/images/sidebar/waybill-agentDelivery.svg'
import waybillInternationalExpress from '@/assets/images/sidebar/waybill-internationalExpress.svg'
import waybillDrafts from '@/assets/images/sidebar/waybill-drafts.svg'
import waybillReservationView from '@/assets/images/sidebar/waybill-reservationView.svg'
import waybillLastRoute from '@/assets/images/sidebar/waybill-lastRoute.svg'
import waybillTransferWarehouse from '@/assets/images/sidebar/waybill-transferWarehouse.svg'
import waybillDropShipping from '@/assets/images/sidebar/waybill-agentDelivery.svg'
import waybillPlatform from '@/assets/images/sidebar/waybill-platform.svg'
export default [
	{
		name: 'waybill',
		path: '/waybill',
		component: () => import("@/views/waybill"),
		redirect: "/waybill/specialLine",
		children: [
			{
				name: 'waybillSpecialLine',
				path: '/waybill/specialLine',
				component: () => import("@/views/waybill/specialLine"),
				redirect: "/waybill/specialLine/index",
				meta: {
					title: '头程运单',
					icon: WaybillSpecialLine,
					isShowing: true,
					isMulti: false,
				},
				children: [
					{
						name: 'waybillSpecialLineIndex',
						path: '/waybill/specialLine/index',
						component: () => import("@/views/waybill/specialLine/specialLine-index"),
						meta: {
							title: '头程运单',
							isShowing: false,
							activeMenu: 'waybillSpecialLine'
						}
					},
					{
						name: 'waybillSpecialLineNewWaybill',
						path: '/waybill/specialLine/newWaybill',
						component: () => import("@/views/waybill/specialLine/specialLine-newWaybill"),
						meta: {
							title: '新建运单',
							isShowing: false,
							activeMenu: 'waybillSpecialLine'
						}
					},
					{
						name: 'waybillSpecialLineDetail',
						path: '/waybill/specialLine/detail',
						component: () => import("@/views/waybill/specialLine/specialLine-detail"),
						meta: {
							title: '运单详情',
							isShowing: false,
							activeMenu: 'waybillSpecialLine'
						}
					},
				]
			},
			{
				name: 'waybillLastRouteOrder',
				path: '/waybill/lastRouteOrder',
				component: () => import("@/views/waybill/lastRouteOrder"),
				redirect: "/waybill/lastRouteOrder/index",
				meta: {
					title: '后端运单',
					icon: waybillLastRoute,
					isShowing: true,
					isMulti: false,
				},
				children: [
					{
						name: 'waybillLastRouteOrderIndex',
						path: '/waybill/lastRouteOrder/index',
						component: () => import("@/views/waybill/lastRouteOrder/lastRouteOrder-index"),
						meta: {
							title: '后端运单',
							isShowing: false,
							activeMenu: 'waybillLastRouteOrder'
						}
					},
					{
						name: 'waybillLastRouteOrderNewWaybill',
						path: '/waybill/lastRouteOrder/newWaybill',
						component: () => import("@/views/waybill/lastRouteOrder/lastRouteOrder-newWaybill"),
						meta: {
							title: '新建运单',
							isShowing: false,
							activeMenu: 'waybillLastRouteOrder'
						}
					},
					{
						name: 'waybillLastRouteOrderDetail',
						path: '/waybill/lastRouteOrder/detail',
						component: () => import("@/views/waybill/lastRouteOrder/lastRouteOrder-detail"),
						meta: {
							title: '运单详情',
							isShowing: false,
							activeMenu: 'waybillLastRouteOrder'
						}
					},
				]
			},
			{
				name: 'waybillTransferWarehouse',
				path: '/waybill/transferWarehouse',
				component: () => import("@/views/waybill/transferWarehouse"),
				redirect: "/waybill/transferWarehouse/index",
				meta: {
					title: '转仓运单',
					icon: waybillTransferWarehouse,
					isShowing: true,
					isMulti: false,
				},
				children: [
					{
						name: 'waybillTransferWarehouseIndex',
						path: '/waybill/transferWarehouse/index',
						component: () => import("@/views/waybill/transferWarehouse/transferWarehouse-index"),
						meta: {
							title: '转仓运单',
							isShowing: false,
							activeMenu: 'waybillTransferWarehouse'
						}
					},
					{
						name: 'waybillTransferWarehouseCreateOrder',
						path: '/waybill/transferWarehouse/createOrder',
						component: () => import("@/views/waybill/transferWarehouse/transferWarehouse-createOrder"),
						meta: {
							title: '新建运单',
							isShowing: false,
							activeMenu: 'waybillTransferWarehouse'
						}
					},
					{
						name: 'waybillTransferWarehouseDetail',
						path: '/waybill/transferWarehouse/detail',
						component: () => import("@/views/waybill/transferWarehouse/transferWarehouse-detail"),
						meta: {
							title: '运单详情',
							isShowing: false,
							activeMenu: 'waybillTransferWarehouse'
						}
					},
				]
			},
			// {
			// 	name: 'waybillPlatformOrder',
			// 	path: '/waybill/platformOrder',
			// 	component: () => import("@/views/waybill/platformOrder"),
			// 	redirect: "/waybill/platformOrder/index",
			// 	meta: {
			// 		title: '平台运单',
			// 		icon: waybillPlatform,
			// 		isShowing: true,
			// 		isMulti: false,
			// 	},
			// 	children: [
			// 		{
			// 			name: 'waybillPlatformOrderIndex',
			// 			path: '/waybill/platformOrder/index',
			// 			component: () => import("@/views/waybill/platformOrder/platformOrder-index"),
			// 			meta: {
			// 				title: '平台运单',
			// 				isShowing: false,
			// 				activeMenu: 'waybillPlatformOrder'
			// 			}
			// 		},
			// 		{
			// 			name: 'waybillPlatformOrderDetail',
			// 			path: '/waybill/platformOrder/detail',
			// 			component: () => import("@/views/waybill/platformOrder/platformOrder-detail"),
			// 			meta: {
			// 				title: '运单详情',
			// 				isShowing: false,
			// 				activeMenu: 'waybillPlatformOrder'
			// 			}
			// 		},
			// 	]
			// },
			{
				name: 'waybillSmallPacket',
				path: '/waybill/smallPacket',
				component: () => import("@/views/waybill/smallPacket"),
				redirect: "/waybill/smallPacket/index",
				meta: {
					title: '小包运单',
					icon: waybillSmallPacket,
					isShowing: true,
					isMulti: false,
				},
				children: [
					{
						name: 'waybillSmallPacketIndex',
						path: '/waybill/smallPacket/index',
						component: () => import("@/views/waybill/smallPacket/smallPacket-index"),
						meta: {
							title: '小包运单',
							isShowing: false,
							activeMenu: 'waybillSmallPacket'
						}
					},
					{
						name: 'waybillSmallPacketNewWaybill',
						path: '/waybill/smallPacket/newWaybill',
						component: () => import("@/views/waybill/smallPacket/smallPacket-newWaybill"),
						meta: {
							title: '新建运单',
							isShowing: false,
							activeMenu: 'waybillSmallPacket'
						}
					},
					{
						name: 'waybillSmallPacketDetail',
						path: '/waybill/smallPacket/detail',
						component: () => import("@/views/waybill/smallPacket/smallPacket-detail"),
						meta: {
							title: '小包详情',
							isShowing: false,
							activeMenu: 'waybillSmallPacket'
						}
					},
				]
			},
			{
				name: 'waybillFirstRouteDropShipping',
				path: '/waybill/firstRouteDropShipping',
				component: () => import("@/views/waybill/firstRouteDropShipping"),
				redirect: "/waybill/firstRouteDropShipping/index",
				meta: {
					title: '头程一件代发运单',
					icon: waybillDropShipping,
					isShowing: true,
					isMulti: false,
				},
				children: [
					{
						name: 'waybillFirstRouteDropShippingIndex',
						path: '/waybill/firstRouteDropShipping/index',
						component: () => import("@/views/waybill/firstRouteDropShipping/firstRouteDropShipping-index"),
						meta: {
							title: '头程一件代发运单',
							isShowing: false,
							activeMenu: 'waybillFirstRouteDropShipping'
						}
					},
					{
						name: 'waybillFirstRouteDropShippingNewWaybill',
						path: '/waybill/firstRouteDropShipping/newWaybill',
						component: () => import("@/views/waybill/firstRouteDropShipping/firstRouteDropShipping-newWaybill"),
						meta: {
							title: '新建运单',
							isShowing: false,
							activeMenu: 'waybillFirstRouteDropShipping'
						}
					},
					{
						name: 'waybillFirstRouteDropShippingDetail',
						path: '/waybill/firstRouteDropShipping/detail',
						component: () => import("@/views/waybill/firstRouteDropShipping/firstRouteDropShipping-detail"),
						meta: {
							title: '运单详情',
							isShowing: false,
							activeMenu: 'waybillFirstRouteDropShipping'
						}
					},
				]
			},
			{
				name: 'waybillOverseasWarehouseDropShipping',
				path: '/waybill/overseasWarehouseDropShipping',
				component: () => import("@/views/waybill/overseasWarehouseDropShipping"),
				redirect: "/waybill/overseasWarehouseDropShipping/index",
				meta: {
					title: '海外仓一件代发',
					icon: waybillDropShipping,
					isShowing: true,
					isMulti: false,
				},
				children: [
					{
						name: 'waybillOverseasWarehouseDropShippingIndex',
						path: '/waybill/overseasWarehouseDropShipping/index',
						component: () => import("@/views/waybill/overseasWarehouseDropShipping/overseasWarehouseDropShipping-index"),
						meta: {
							title: '海外仓一件代发',
							isShowing: false,
							activeMenu: 'waybillOverseasWarehouseDropShipping'
						}
					},
					{
						name: 'waybillOverseasWarehouseDropShippingDetail',
						path: '/waybill/overseasWarehouseDropShipping/detail',
						component: () => import("@/views/waybill/overseasWarehouseDropShipping/overseasWarehouseDropShipping-detail"),
						meta: {
							title: '海外仓一件代发',
							isShowing: false,
							activeMenu: 'waybillOverseasWarehouseDropShipping'
						}
					},
				]
			},
			{
				name: 'waybillDropShippingOrder',
				path: '/waybill/dropShippingOrde',
				component: () => import("@/views/waybill/drop-shipping-order"),
				redirect: "/waybill/dropShippingOrde/index",
				meta: {
					title: '一件代发运单',
					icon: waybillPlatform,
					isShowing: true,
					isMulti: false,
				},
				children: [
					{
						name: 'waybillDropShippingOrderIndex',
						path: '/waybill/dropShippingOrde/index',
						component: () => import("@/views/waybill/drop-shipping-order/drop-shipping-order-index"),
						meta: {
							title: '一件代发运单',
							isShowing: false,
							activeMenu: 'waybillDropShippingOrder'
						}
					},
					{
						name: 'waybillDropShippingOrderDetail',
						path: '/waybill/dropShippingOrde/detail',
						component: () => import("@/views/waybill/drop-shipping-order/drop-shipping-order-detail"),
						meta: {
							title: '运单详情',
							isShowing: false,
							activeMenu: 'waybillDropShippingOrder'
						}
					},
				]
			},
			// {
			//   name: 'waybillFullContainerLoad',
			//   path: '/waybill/fullContainerLoad',
			//   component: () => import("@/views/waybill/fullContainerLoad"),
			//   redirect: "/waybill/fullContainerLoad/index",
			//   meta: {
			//     title: '整柜运单',
			//     icon: waybillFullContainerLoad,
			//     isShowing: true,
			//     isMulti: false,
			//   },
			//   children: [
			//     {
			//       name: 'waybillFullContainerLoadIndex',
			//       path: '/waybill/fullContainerLoad/index',
			//       component: () => import("@/views/waybill/fullContainerLoad/fullContainerLoad-index"),
			//       meta: {
			//         title: '整柜运单',
			//         isShowing: false,
			//         activeMenu: 'waybillFullContainerLoad'
			//       }
			//     },
			//   ]
			// },
			// {
			//   name: 'waybillStorage',
			//   path: '/waybill/storage',
			//   component: () => import("@/views/waybill/storage"),
			//   redirect: "/waybill/storage/index",
			//   meta: {
			//     title: '仓储运单',
			//     icon: waybillStorage,
			//     isShowing: true,
			//     isMulti: false,
			//   },
			//   children: [
			//     {
			//       name: 'waybillStorageIndex',
			//       path: '/waybill/storage/index',
			//       component: () => import("@/views/waybill/storage/storage-index"),
			//       meta: {
			//         title: '仓储运单',
			//         isShowing: false,
			//         activeMenu: 'waybillStorage'
			//       }
			//     },
			//   ]
			// },
			// {
			// 	name: 'waybillAgentDelivery',
			// 	path: '/waybill/agentDelivery',
			// 	component: () => import("@/views/waybill/agentDelivery"),
			// 	redirect: "/waybill/agentDelivery/index",
			// 	meta: {
			// 		title: '一件代发运单',
			// 		icon: waybillAgentDelivery,
			// 		isShowing: true,
			// 		isMulti: false,
			// 	},
			// 	children: [
			// 		{
			// 			name: 'waybillAgentDeliveryIndex',
			// 			path: '/waybill/agentDelivery/index',
			// 			component: () => import("@/views/waybill/agentDelivery/agentDelivery-index"),
			// 			meta: {
			// 				title: '一件代发运单',
			// 				isShowing: false,
			// 				activeMenu: 'waybillAgentDelivery'
			// 			}
			// 		},
			// 		{
			// 			name: 'waybillAgentDeliveryNew',
			// 			path: '/waybill/agentDelivery/new',
			// 			component: () => import("@/views/waybill/agentDelivery/agentDelivery-new"),
			// 			meta: {
			// 				title: '新建一件代发',
			// 				isShowing: false,
			// 				activeMenu: 'waybillAgentDelivery'
			// 			}
			// 		},
			// 		{
			// 			name: 'waybillAgentDeliveryEdit',
			// 			path: '/waybill/agentDelivery/edit',
			// 			component: () => import("@/views/waybill/agentDelivery/agentDelivery-edit"),
			// 			meta: {
			// 				title: '编辑一件代发',
			// 				isShowing: false,
			// 				activeMenu: 'waybillAgentDelivery'
			// 			}
			// 		},
			// 		{
			// 			name: 'waybillAgentDeliveryDetail',
			// 			path: '/waybill/agentDelivery/detail',
			// 			component: () => import("@/views/waybill/agentDelivery/agentDelivery-detail"),
			// 			meta: {
			// 				title: '一件代发详情',
			// 				isShowing: false,
			// 				activeMenu: 'waybillAgentDelivery'
			// 			}
			// 		},
			// 	]
			// },
			// {
			// 	name: 'waybillReservationView',
			// 	path: '/waybill/reservationView',
			// 	component: () => import("@/views/waybill/reservationView"),
			// 	redirect: "/waybill/reservationView/index",
			// 	meta: {
			// 		title: '预约查看',
			// 		icon: waybillReservationView,
			// 		isShowing: true,
			// 		isMulti: false,
			// 	},
			// 	children: [
			// 		{
			// 			name: 'waybillReservationViewIndex',
			// 			path: '/waybill/reservationView/index',
			// 			component: () => import("@/views/waybill/reservationView/reservationView-index"),
			// 			meta: {
			// 				title: '预约查看',
			// 				isShowing: false,
			// 				activeMenu: 'waybillReservationView'
			// 			}
			// 		},
			// 	]
			// },
			// {
			//   name: 'waybillInternationalExpress',
			//   path: '/waybill/internationalExpress',
			//   component: () => import("@/views/waybill/internationalExpress"),
			//   redirect: "/waybill/internationalExpress/index",
			//   meta: {
			//     title: '国际快递运单',
			//     icon: waybillInternationalExpress,
			//     isShowing: true,
			//     isMulti: false,
			//   },
			//   children: [
			//     {
			//       name: 'waybillInternationalExpressIndex',
			//       path: '/waybill/internationalExpress/index',
			//       component: () => import("@/views/waybill/internationalExpress/internationalExpress-index"),
			//       meta: {
			//         title: '国际快递运单',
			//         isShowing: false,
			//         activeMenu: 'waybillInternationalExpress'
			//       }
			//     },
			//   ]
			// },
			// {
			// 	name: 'waybillReturnGoods',
			// 	path: '/waybill/returnGoods',
			// 	component: () => import("@/views/waybill/returnGoods"),
			// 	redirect: "/waybill/returnGoods/index",
			// 	meta: {
			// 		title: '退货/美墨',
			// 		icon: WaybillSpecialLine,
			// 		isShowing: true,
			// 		isMulti: false,
			// 	},
			// 	children: [
			// 		{
			// 			name: 'waybillReturnGoodsIndex',
			// 			path: '/waybill/returnGoods/index',
			// 			component: () => import("@/views/waybill/returnGoods/returnGoods-index"),
			// 			meta: {
			// 				title: '退货/美墨',
			// 				isShowing: false,
			// 				activeMenu: 'waybillReturnGoods'
			// 			}
			// 		},
			// 	]
			// },
			// {
			// 	name: 'waybillDrafts',
			// 	path: '/waybill/drafts',
			// 	component: () => import("@/views/waybill/drafts"),
			// 	redirect: "/waybill/drafts/index",
			// 	meta: {
			// 		title: '草稿箱',
			// 		icon: waybillDrafts,
			// 		isShowing: true,
			// 		isMulti: false,
			// 	},
			// 	children: [
			// 		{
			// 			name: 'waybillDraftsIndex',
			// 			path: '/waybill/drafts/index',
			// 			component: () => import("@/views/waybill/drafts/drafts-index"),
			// 			meta: {
			// 				title: '草稿箱',
			// 				isShowing: false,
			// 				activeMenu: 'waybillDrafts'
			// 			}
			// 		},
			// 	]
			// }
		]
	}
]